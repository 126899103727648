let common = {
  notifyResponseError (error) {
    let message = ''
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        message = error.response.data.detail
      } else if ((error.response.status === 400 || error.response.status === 404) && error.response.data.message) {
        message = error.response.data.message
      } else if (error.response.status === 404) {
        message = 'Not found!'
      } else if (error.response.status === 400) {
        let key = 1
        let keyItem = 1
        if (error.response.data[0] && error.response.data[0].non_field_errors) {
          for (key in error.response.data[0].non_field_errors) {
            if (error.response.data[0].non_field_errors.hasOwnProperty(key)) {
              message = `${error.response.data[0].non_field_errors[key]}`
              key++
            }
          }
        } else if (error.response.data) {
          for (key in error.response.data) {
            if (typeof (error.response.data[key]) === 'object') {
              for (keyItem in error.response.data[key]) {
                message = `${typeof (error.response.data[key][keyItem]) === 'object' ?keyItem : ''} ${error.response.data[key][keyItem]}`
              }
            } else {
              if (error.response.data.hasOwnProperty(key)) {
                message = `${error.response.data[key]}`
              }
            }
          }
        } else {
          for (key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              message = `${key}: ${error.response.data[key]}`
              key++
            }
          }
        }
      } else {
        message = 'Error. Please try again!'
      }
      this.notify({
        title: 'Failed',
        text: message,
        color: '#EA5455'
      })
      return message
    }
  },
  loading (type) {
    if (type === 'open') {
      document.getElementById("loader").classList.add('loader');
      document.getElementById("spinner").classList.add('spinner');
    }
    else {
      document.getElementById("loader").classList.remove('loader');
      document.getElementById("spinner").classList.remove('spinner');
    }
  },
  notify (notifyObj) {
    document.getElementById('notifyTitle').innerHTML = notifyObj.title
    document.getElementById('notifyText').innerHTML = notifyObj.text
    document.getElementById('notify').style.backgroundColor = notifyObj.color
    document.getElementById('notify').classList.remove('hidden')
    setTimeout(() => {
      document.getElementById('notify').classList.add('hidden')
    }, 2700)
  },
  zoomImage (url) {
    document.getElementById('productImage').src = url ? url[0] : ''
    document.getElementById('containerImage').classList.add('loader')
    document.getElementById('containerImage').classList.remove('hidden')
  },
  manageSearch (searchVal, loadData, interval, digits) {
    const length = digits ? digits : 3
    if ((searchVal.target && searchVal.target.value.length >= length) || searchVal.length >= length) {
      return searchVal.target ? loadData() : setTimeout(() => { loadData() }, interval ? interval : 3000)
    } else if (searchVal.length === 0) {
      return loadData()
    }
  }
}
export default common
