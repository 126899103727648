import axios from 'axios'
import common  from '@/assets/utils/common'

const request = (url, VueJs, endpoint, requestType, data, authenticated, successCallback = null, errorCallback = null) => {
  common.loading('open')
  let headers = null
  if (authenticated) {
    headers = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
    
    const requestObject = {
      get: axios.get,
      post: axios.post,
      delete: axios.delete,
      put: axios.put,
      patch: axios.patch
    }


    let request = null
    if (data) {
      request = requestObject[requestType](url, data, headers)
    } else {
      request = requestObject[requestType](url, headers)
    }
    request
      .then(response => {
        if (successCallback) {
          successCallback(response)
          common.loading('close')
        }
      })
      .catch(error => {
        if (!errorCallback) {
          common.notifyResponseError(error)
          common.loading('close')
        } else {
          errorCallback(error)
        }
      })
  }
}
const sendRequest = (VueJs, endpoint, requestType, data = null, authenticated, successCallback = null, errorCallback = null) => {
  const url = `${process.env.VUE_APP_API_URL}/${endpoint}`
  request(url, VueJs, endpoint, requestType, data, authenticated, successCallback, errorCallback)
}

export { sendRequest }
